<template>
  <div id="chat-app">
    <div
      class="chat__bg app-fixed-height chat-content-area border border-solid d-theme-border-grey-light border-t-0 border-r-0 border-b-0 border-l-0"
      :class="{
        'sidebar-spacer--wide': clickNotClose,
        'flex items-center justify-center': activeChatUser === null,
      }"
    >
      <div class="chat__navbar">
        <chat-navbar
          :is-sidebar-collapsed="!clickNotClose"
          :user-id="activeChatUser"
          :is-pinned-prop="isChatPinned"
          :user-data="selectedContact"
          @showProfileSidebar="updateUserProfileId"
          @toggleIsChatPinned="toggleIsChatPinned"
          @close-chat="closeChat"
        ></chat-navbar>
      </div>
      <template v-if="activeChatUser" id="test">
        <VuePerfectScrollbar
          ref="chatLogPS"
          class="chat-content-scroll-area border border-solid d-theme-border-grey-light"
          :settings="settings"
        >
          <div ref="chatLog" class="chat__log">
            <chat-log v-if="activeChatUser" :user-id="activeChatUser" :chat-data="userData"></chat-log>
          </div>
        </VuePerfectScrollbar>
        <div class="chat__input flex p-4 bg-white flex-col sm:flex-row sm:items-center">
          <!--          eslint-disable-next-line vue/no-mutating-props-->
          <vs-textarea v-model="typedMessageSync" placeholder="Type Your Message" class="w-full mb-3 sm:mb-0" />
          <!-- @keydown.shift.enter="null"
            @keydown.enter="sendMsg" -->
          <vs-icon size="medium" color="#adca63" icon="send" class="sm:ml-4 hidden sm:block cursor-pointer" @click="sendMsg"></vs-icon>
          <vs-button id="send" icon-after icon="send" class="bg-primary-gradient sm:ml-4 sm:hidden" type="filled" @click="sendMsg"
            >Send</vs-button
          >
        </div>
      </template>
      <template v-else>
        <div class="flex flex-col items-center">
          <feather-icon icon="MessageSquareIcon" class="mb-4 bg-white p-8 shadow-md rounded-full" svg-classes="w-16 h-16"></feather-icon>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */

import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import contacts from './contacts';
import ChatNavbar from './ChatNavbar.vue';
import ChatLog from './ChatLog.vue';
export default {
  name: 'ChatSide',
  components: {
    VuePerfectScrollbar,
    ChatNavbar,
    ChatLog,
  },
  props: {
    clickNotClose: Boolean,
    isChatPinned: Boolean,
    userData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    settings: {
      type: Object,
      default: () => {
        return { maxScrollbarLength: 60, wheelSpeed: 0.7 };
      },
    },
    activeChatUser: { type: String, default: '' },
    selectedContact: {
      type: [Object, null],
      default: () => {
        return {};
      },
    },
    selectedNumerContact: { type: Number, default: -1 },
    typedMessage: { type: String, default: '' },
  },
  data() {
    return {
      active: true,
      search: '',
      contacts: contacts,
      activeProfileSidebar: false,
      userProfileId: -1,
      isChatSidebarActive: true,
      windowWidth: window.innerWidth,
      typedMessageSync: this.typedMessage,
    };
  },
  computed: {
    profile() {
      return this.$store.getters['employerModule/getUser'];
    },
    chats() {
      var data = this.$store.state['chatModule'].data;
      return this.filterFunc(data);
    },
  },
  mounted() {
    if (this.application) {
      this.updateActiveChatUser(this.application);
      this.selectedContact = this.application;
    }
  },
  created() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.handleWindowResize);
    });
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleWindowResize);
  },
  methods: {
    closeChat() {
      this.$emit('close');
    },
    filterFunc(chatData) {
      var filtered = Object.keys(chatData)
        .filter((key) => {
          return (
            chatData[key].userData.userName.toLowerCase().includes(this.search.toLowerCase()) ||
            chatData[key].jobData.jobTitle.toLowerCase().includes(this.search.toLowerCase())
          );
        })
        .map((key) => {
          return chatData[key];
        });
      return filtered;
    },
    updateUserProfileId(userId) {
      this.userProfileId = userId;
      this.activeProfileSidebar = !this.activeProfileSidebar;
    },
    updateActiveChatUser(contactId) {
      this.activeChatUser = contactId?.applicationsId ? contactId.applicationsId : contactId;
      this.selectedContact = contactId;
      let chatData = this.$store.getters['chatModule/chatDataOfUser'](this.activeChatUser);
      this.userData = chatData;
      if (chatData && chatData.userCount > 0) {
        chatData.userCount = 0;
        // this.$store.dispatch('chatModule/set', chatData);
      }
      if (chatData) this.isChatPinned = chatData.isPinned;
      else this.isChatPinned = true;
      this.typedMessageSync = '';
    },
    showProfileSidebar(userId) {
      this.userProfileId = userId;
      this.activeProfileSidebar = !this.activeProfileSidebar;
    },
    sendMsg() {
      if (!this.typedMessageSync) return;

      const payload = {
        isPinned: this.isChatPinned === undefined ? false : this.isChatPinned,
        messages: [
          {
            createdAt: new Date(),
            message: this.typedMessageSync,
            senderId: this.profile.employerId,
            video: '',
          },
        ],
        employerId: this.$store.state.employerModule.data.employerId,
        lastSentBy: this.profile.employerId,
        updatedAt: new Date(),
        type: 'messaged',
        applicationsId: this.activeChatUser,
        jobData: this.$store.state.applicationsModule.data[this.activeChatUser].jobData,
        userData: this.$store.state.applicationsModule.data[this.activeChatUser].userData,
        userId: this.$store.state.applicationsModule.data[this.activeChatUser].userId,
        jobId: this.$store.state.applicationsModule.data[this.activeChatUser].jobId,
      };
      const chatData = this.$store.getters['chatModule/chatDataOfUser'](this.activeChatUser);
      var messagePayload;
      var empCount = 0;
      const messages = payload.messages;
      if (typeof chatData !== 'undefined') {
        messagePayload = [...chatData.messages, ...messages];
        empCount = chatData.empCount + 1;
      } else {
        messagePayload = [...messages];
        empCount = 1;
      }

      delete payload.messages;
      const newMessage = {
        ...chatData,
        ...payload,
        messages: messagePayload,
        empCount: empCount,
        type: 'messaged',
        userCount: 0,
      };
      if (typeof chatData === 'undefined') {
        this.$store.state.chatModule.data[newMessage.applicationsId] = newMessage;
        this.$store.dispatch('chatModule/set', { ...newMessage, empUnread: 0 });
        this.$store.dispatch('chatModule/sendChatMessage', newMessage);
      } else {
        newMessage.id = newMessage.applicationsId;
        this.$store.dispatch('chatModule/patch', newMessage);
        this.$store.dispatch('chatModule/sendChatMessage', newMessage);
      }

      this.typedMessageSync = '';
      // this.updateActiveChatUser(this.$store.state['chatModule'].data[this.activeChatUser]);
      this.$emit('update-active-chat-user', this.$store.state['chatModule'].data[this.activeChatUser]);
      this.$refs.chatLogPS.$el.scrollTop = this.$refs.chatLog.scrollHeight;
    },
    toggleIsChatPinned(value) {
      this.isChatPinned = value === undefined ? false : value;
    },
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/vuesax/apps/chat.scss';
.vs-con-textarea {
  width: 90%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 0px;
}
</style>
